$(function () {
    //Inicializa o datatable quando houve o id chamados
    if ($("#chamados").length != 0) {
        $('#chamados').DataTable({
            "language": {
                "lengthMenu": "",
                "zeroRecords": "Nenhum chamado encontrado.",
                "info": "",
                "infoEmpty": "",
                "infoFiltered": "",
                "paginate": {
                    "first": "",
                    "last": "",
                    "next": "Próximo >>",
                    "previous": "<< Anterior"
                },
                "search": "Buscar:"
            },
            "order": [[0, "desc"]]
        });
    }

    window.statusSistemas();
    window.tooltip();
});


window.statusSistemas = () => {
    $.ajax({
        url: statusSistemasUrl,
        success: function (data) {
            const sistemas = data;
            let numNotifications = 0;
            $.each(sistemas, function(i, sistema)
            {
                // Inclui alerta para sistema na situação "Em Atualização" ou "Com Problema"
                if ([2,3].includes(sistema.status_id)) {
                    numNotifications++;
                }

                // Para cada incidente, adiciona um alerta
                numNotifications += sistema.incidentes.length;
            });
            
            if (numNotifications > 0) {
                $("#status-sistemas-badge").text(numNotifications);
                $("#status-sistemas").attr('title', 'Há alertas sobre os sistemas. Clique aqui para saber mais!');
                $("#status-sistemas-label").text('Há alertas sobre os sistemas');
                // Caso algum sistema esteja com problema, exibe alerta "DANGER"
                sistemas.some(sistema => sistema.status_id === 3) ?
                    $("#status-sistemas-badge").removeClass("bg-success bg-warning").addClass('bg-danger text-white') : 
                    $("#status-sistemas-badge").removeClass("bg-success bg-danger text-white").addClass('bg-warning');
            } else {                              
                $("#status-sistemas-badge").text(sistemas.length);
                $("#status-sistemas-badge").removeClass("bg-warning bg-danger").addClass('bg-success text-white');
                $("#status-sistemas").attr('title', 'Todos os sistemas estão disponíveis. Clique aqui para saber mais!');
                $("#status-sistemas-label").text('Todos os sistemas estão disponíveis');

            }

            window.tooltip();
        }
    });
}

window.tooltip = () => {
    // Inicializa o tooltip do bootstrap
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    });
}

window.changeTheme = () => {
    $.ajax({
        url: themeUrl,
        success: function (currentTheme) {
            var newTheme = ''
            if (currentTheme == 'light') {
                newTheme = 'dark'
                document.getElementById('app-theme').src = jsDarkUrl;
            } else {
                newTheme = 'light'
                document.getElementById('app-theme').src = jsLightUrl;
            }
            $.ajax({
                url: changeThemeUrl,
                data: { 'theme': newTheme },
                success: function() {
                    window.location.reload();
                }
            });
        }
    });
}

window.loadModulosSistema = (event) => {
    var selectModulo = document.getElementById('id_modulo');
    var nomeLabel = "";
    const sistemaId = event.target.value;
    $('#id_modulo').empty();
    if (sistemaId == 0){
        selectModulo.options[0] = new Option('-- SELECIONE SISTEMA --', '0', false, true);
        return;
    }

    if (event.target.selectedOptions.length > 0) {
        nomeLabel = event.target.selectedOptions[0].label;
    }

    $.ajax({
        url: modulosSistemaUrl,
        data: {'id': sistemaId},
        success: function (data) {
            const modulos = data;
            
            if (modulos.length == 0){
                selectModulo.options[0] = new Option(nomeLabel, sistemaId, false, true);
            } else {
                selectModulo.options[0] = new Option('-- SELECIONE --', 0, false, true);
            }

            $.each(modulos, function(i, modulo)
            {
                selectModulo.options[i+1] = new Option(modulo.nome, modulo.id, false, false);
            });
        }
    });

}
